import * as bootstrap from 'bootstrap'
import PerfectScrollbar from 'perfect-scrollbar'
import Utils from '../../../utils'
import DefaultController from "../../defaultController";
import TicketEntity from "../entity";
import TicketShowServices from "./show/services";
import TicketShowReports from "./show/reports";

export default class TicketOverviewNew extends DefaultController {
    async init() {
        this.entity = "tickets";
        this.childs = {
        }
        await super.init();
        //@ts-ignore
        jQuery("#ticket_customer_id").on("select2:select", (e: any) => {
            //@ts-ignore
            jQuery("#ticket_customer_location_id").val(null).trigger("change");
            //@ts-ignore
            jQuery("#ticket_customer_contact_id").val(null).trigger("change");
        })
        const defaults = await Utils.entity.request("/api/v1/tickets/defaults", "GET")
        if (defaults.status === 200) {
            const data = defaults.data;

            const $newOptionStatus = jQuery("<option selected='selected'></option>").val(data.status.uuid).text(data.status.name)
            jQuery("#newTicketForm #ticket_status_id").append($newOptionStatus).trigger('change');
            const $newOptionPriority = jQuery("<option selected='selected'></option>").val(data.priority.uuid).text(data.priority.name)
            jQuery("#newTicketForm #ticket_priority_id").append($newOptionPriority).trigger('change');
        }


        const url = new URL(document.location.href);
        const inheritedItems = ["customer", "customer_location", "customer_contact", "parent", "assignee", "priority", "project", "status", "category", "queue"]
        inheritedItems.forEach((item: any) => {
            if (url.searchParams.get(item)) {
                const vals = (url.searchParams.get(item) as string).split(",")
                vals.forEach((val: string) => {
                    const v = val.split("|")
                    const $newOption = jQuery("<option selected='selected'></option>").val(v[0]).text(v[1])
                    console.log("ADD", $newOption, `#ticket_${item}_id`)
                    jQuery(`#ticket_${item}_id`).append($newOption).trigger('change');
                })
            }
        })
    }


    onSelectGetFilterOptions(elem: any) {
        if (elem.getAttribute("data-entity") !== "customers") {
            return {
                customer_id: (document.querySelector("#ticket_customer_id") as HTMLSelectElement).value
            }
        } else {
            return {
            }
        }
    }

    protected getEntityData(elem: any) {
        return TicketEntity.getEntityData(elem)
    }

    bindListeners() {
        console.log(document.querySelector("#ticket_save"));
        (document.querySelector("#ticket_save") as HTMLButtonElement).addEventListener("click", async (e: any) => {
            e.preventDefault();
            const elem = document.querySelector("#newTicketForm") as HTMLFormElement;
            if (elem.reportValidity()) {
                const entityData = this.getEntityData(elem)
                console.log("entityData", entityData)
                await Utils.showLoader()
                const r = await Utils.entity.upsert(entityData, this.entity)
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('ticket.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                    setTimeout(() => {
                        document.location.href = `/${(window as any).currentLocale}/ticket/tasks/${r.data[0].uuid}`
                    }, 1000)
                }
            }
        });
    }

    addComment(comment: any) {
        let html = '';
        html += `<li class="chat-message ${comment.direction === "OUT" ? 'chat-message-right' : ''}">
                        <div class="d-flex overflow-hidden">
                          <div class="chat-message-wrapper flex-grow-1">
                            <div class="chat-message-text ${comment.internal ? 'internal' : ''}">
                              <p class="mb-0">`
        html += comment.description
        if (comment.attachments.length > 0) {
            comment.attachments.forEach((attachment: any) => {
                const url = attachment.url;
                html += `<br /><img src="${url}" style="height: 120px;cursor: pointer;" class="img-thumbnail ticket_comment_img"/>`
            })
        }
        html += `</p></div><div class="text-end text-muted mt-1">`
        if (comment.direction === "OUT") {
            html += `<i data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="${comment.internal ? 'Intern' : 'Extern'}" class="ti ti-checks ti-xs me-1 ${comment.internal ? 'ti-lock' : 'ti-lock-open'}"></i>`
        }
        html += `<small>${new Date(Date.parse(comment.created_at)).toLocaleString("de-DE", {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        })} | ${comment.from}</small>
                            </div>
                          </div>
                        </div>
                      </li>`
        return html;
    }

    setupTimer(data: any) {
        const timerElem = document.querySelector("#ticket_footer_timer") as HTMLElement;
        const creationDate = new Date(Date.parse(data.created_at))
        const currentDate = new Date();
        //@ts-ignore
        const diff = currentDate - creationDate;
        timerElem.innerHTML = Utils.msToHMS(diff);
    }
}